.invitations-container .invitations .invitation:not(:last-child) {
  margin-bottom: 15px;
  padding-bottom: 15px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}
.invitations-container .invitations .invitation .avatar {
  display: block;
  width: 36px;
  height: 36px;
  flex: 1 0 36px;
}
.invitations-container .invitations .invitation .user-info .name h6 {
  color: #004254;
}
.invitations-container .invitations .invitation .user-info .name .designation {
  color: #6f7a7c;
  font-size: 12px;
  line-height: 14px;
}
.invitations-container .invitations .invitation .user-info .action .basic_btn {
  height: auto;
  padding: 8px 15px;
  overflow: unset;
  font-weight: 700;
}
.invitations-container
  .invitations
  .invitation
  .user-info
  .action
  .basic_btn.accept {
  background-color: #37aab2;
}
.invitations-container
  .invitations
  .invitation
  .user-info
  .action
  .basic_btn.accept:hover {
  background-color: #fbbb21;
  color: #004254;
}
.invitations-container .invitations .invitation .user-info .action i {
  font-size: 18px;
}
.invitations-container .invitations .invitation .sent-info {
  background-color: #eaeaea;
  color: #6f7a7c;
  font-size: 14px;
  line-height: 16px;
}
.invitations-container .invitations .invitation .sent-info a {
  color: #004254;
}
.invitations-container .invitations .invitation .comment {
  color: #004254;
  font-size: 14px;
  line-height: 16px;
}
.invitations-container.sent .invitations .invitation .user-info .action a {
  color: #004254;
}
.invitations-container.sent
  .invitations
  .invitation
  .user-info
  .action
  .status.pending {
  color: #ff4b4b;
}
.invitations-container.sent
  .invitations
  .invitation
  .user-info
  .action
  .status.approved {
  color: #37aab2;
}
.invitations-container.sent .invitations .invitation .user-info .action .date {
  color: #6f7a7c;
}
